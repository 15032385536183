<template>
  <div>
    <v-card class="mb-8">
      <v-alert
        v-model="isAlertVisible"
        :color="error ? 'error' : 'success'"
        dismissible
        text
        transition="slide-y-transition"
      >
        {{ message }}
      </v-alert>
      <div class="m-2">
        <v-row class="ml-5 mr-5">
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="$store.state.reductCode.field.keyword"
              label="Rechercher globalement"
              outlined
              dense
              placeholder="Rechercher globalement"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="$store.state.reductCode.field.label"
              label="Filtrer par libellé"
              outlined
              dense
              placeholder="Filtrer par libellé"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="$store.state.reductCode.field.value"
              label="Filtrer par valeur"
              outlined
              dense
              placeholder="Filtrer par valeur"
              hide-details="auto"
              clearable
              @input="filterItems"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="$store.state.reductCode.field.is_percent"
              :items="responses"
              label="Avec pourcentage?"
              outlined
              dense
              placeholder="Avec pourcentage?"
              clearable
              hide-details="auto"
              persistent-hint
              single-line
              @input="filterItems"
            ></v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card>
      <v-data-table
        :headers="headers"
        :loading="isDialogVisible"
        loading-text="Chargement..."
        :items="$store.getters['reductCode/reduct_codes']"
        :items-per-page="$store.getters['reductCode/per_page']"
        :page.sync="$store.getters['reductCode/current_page']"
        dense
        fixed-header
        height="500"
        hide-default-footer
        @page-count="currentPage = $event"
      >
        <template #[`item.created_at`]="{item}">
          {{ formattingDate(`${item.created_at}`) }}
        </template>

        <template #[`item.updated_at`]="{item}">
          {{ formattingDate(`${item.updated_at}`) }}
        </template>

        <template #[`item.deleted_at`]="{item}">
          {{ item.deleted_at ? formattingDate(`${item.deleted_at}`) : null }}
        </template>
        <template #[`item.end_date`]="{item}">
          {{ formattingDateExHour(`${item.end_date}`) }}
        </template>

        <template #[`item.value`]="{item}">
          <v-chip
            small
            :color="new Date(item.end_date) > new Date() ? 'primary' : 'error'"
          >
            {{ item.is_percent === true ? `${item.value} %` : formatAmount(item.value) }}
          </v-chip>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <buttons-group
            :item="item"
            :remove="false"
            :edit="false"
            :lock="false"
            :status="false"
            @view="openDetails(item)"
          />
        </template>
      </v-data-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <v-text-field
              :value="$store.state.reductCode.field.paginate"
              label="Lignes par page:"
              type="number"
              min="-1"
              max="15"
              hide-details
              @input="getListPerPage"
            ></v-text-field>
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="currentPage"
              total-visible="6"
              :length="lastPage"
              @input="getList"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import {
  mdiAccountLock, mdiCashMinus, mdiCashPlus,
  mdiDeleteOutline,
  mdiDotsVertical, mdiEyeOutline, mdiLockOpen,
  mdiPencilOutline, mdiRefresh,
  mdiSquareEditOutline,
} from '@mdi/js'
import { formatDate, formatDateWithoutHour } from '@core/date/dateFunctions'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import { useRouter } from '@core/utils'
import store from '@/store'
import ButtonsGroup from "@core/components/buttons/ButtonsGroup";
import {intToCurrency} from "@core/dynamicComponents/dynamicComponents";

export default {
  name: 'UserReductCodeList',
  components: {ButtonsGroup, LoaderDialog },
  setup() {
    const date = ref(null)
    const menu = ref(false)
    const picker = ref(null)
    const message = ref(null)
    const menuref = ref(null)
    const currentPage = ref(null)
    const lastPage = ref(null)
    const isDialogVisible = ref(false)
    const isAlertVisible = ref(false)
    const error = ref(false)
    const { router } = useRouter()

    const save = d => {
      menuref.value.save(d)
    }

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const getList = (page = 1) => {
      isDialogVisible.value = true
      store.state.reductCode.field.user_id = store.state.user.user.id
      store.dispatch('reductCode/getReductCodesList', { page, field: store.state.reductCode.field }).then(() => {
        currentPage.value = store.getters['reductCode/current_page']
        lastPage.value = store.getters['reductCode/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
      }).catch(err => {
        message.value = err.response.data.message
        error.value = true
        isAlertVisible.value = true
      })
    }

    const getListPerPage = per_page => {
      isDialogVisible.value = true
      store.state.reductCode.field.paginate = per_page
      store.dispatch('reductCode/getReductCodesList', { field: store.state.reductCode.field }).then(() => {
        currentPage.value = store.getters['reductCode/current_page']
        lastPage.value = store.getters['reductCode/last_page']
        isDialogVisible.value = false
        isAlertVisible.value = false
        // eslint-disable-next-line no-shadow
      }).catch(error => {
        message.value = error.response.data.message
        error.value = error.response.data.message
        isAlertVisible.value = true
      })
    }

    const filterItems = () => {
      setTimeout(() => {
        getList()
      }, 1500)
    }

    const openDetails = item => {
      router.push({ name: 'showreductcode', params: { id: item.id } })
    }

    const formattingDate = date => formatDate(date)

    const formattingDateExHour = date => formatDateWithoutHour(date)

    onMounted(() => {
      getList(store.getters['reductCode/current_page'])
    })

    return {
      formatAmount,
      getList,
      openDetails,
      formattingDate,
      formattingDateExHour,
      filterItems,
      getListPerPage,
      save,
      date,
      currentPage,
      lastPage,
      message,
      isAlertVisible,
      isDialogVisible,
      error,
      menu,
      picker,
      menuref,
      responses: [
        { text: 'OUI', value: true },
        { text: 'NON', value: false },
      ],
      headers: [
        { text: 'LABEL', value: 'label' },
        { text: 'CODE', value: 'code' },
        { text: 'VALEUR', value: 'value' },
        { text: 'PROPRIETAIRE', value: 'user.name' },
        { text: 'DATE DE CREATION', value: 'created_at' },
        { text: 'DATE DE FIN', value: 'end_date' },
        { text: 'DATE DE MISE A JOUR', value: 'updated_at' },
        { text: 'DATE DE SUPPRESSION', value: 'deleted_at' },
        { text: 'ACTIONS', value: 'actions' },
      ],
      itemsPerPage: parseInt(store.state.roadPayment.field.paginate, 10),

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAccountLock,
        mdiLockOpen,
        mdiEyeOutline,
        mdiRefresh,
        mdiCashMinus,
        mdiCashPlus,
      },
    }
  },

}
</script>

<style scoped>

</style>
