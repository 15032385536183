<template>
  <div
    id="user-view"
    :key="componentKey"
  >
    <custom-toolbar
      :title="'Détails de l\'utilisateur'"
      @refresh="refreshObjet"
    />
    <v-row class="mt-1">
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="360"
          type="card-avatar, article, article, article, article, actions"
        ></v-skeleton-loader>
        <user-bio-panel
          v-show="!isDialogVisible"
          :user-data="user"
        />
      </v-col>
      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-skeleton-loader
          v-show="isDialogVisible"
          boilerplate
          class="mx-auto"
          elevation="2"
          max-width="840"
          type="table-heading,divider, image, article, article, actions"
        ></v-skeleton-loader>
        <v-tabs
          v-show="!isDialogVisible"
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            v-show="tab.show"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          v-show="!isDialogVisible"
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <license-card
              :license_path="$store.state.user.user.license_driver_path"
              :validated_at="user.license_validated_at"
            />
          </v-tab-item>
          <v-tab-item>
            <user-car-list
              :user_id="$store.state.user.user.id"
              :is-refreshed="isDialogVisible"
            />
          </v-tab-item>
          <v-tab-item>
            {{ $store.state.user.currentUser.menu.road }}
            <user-road-list :is-refreshed="isDialogVisible" />
          </v-tab-item>
          <v-tab-item>
            <user-payment :user="$store.state.user.user" />
          </v-tab-item>
          <v-tab-item>
            <user-alerts-list :user_id="$store.state.user.user.id" />
          </v-tab-item>
          <v-tab-item>
            <user-devices-list :user_id="$store.state.user.user.id" />
          </v-tab-item>
          <v-tab-item>
            <user-reduct-code-list />
          </v-tab-item>
          <v-tab-item>
            <user-locks-list :user_id="$store.state.user.user.id" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getCurrentInstance, onMounted, ref, watch,
} from '@vue/composition-api'
import UserBioPanel from '@core/components/user/UserBioPanel'
import {
  mdiCar,
  mdiLockOpenAlertOutline,
  mdiCashMultiple,
  mdiFileDocumentOutline,
  mdiRoad,
  mdiAlertCircle,
  mdiDevices,
  mdiRefresh, mdiArrowLeftBoldCircleOutline,
  mdiCodeBraces,
} from '@mdi/js'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import LicenseCard from '@core/components/user/LicenseCard'
import UserCarList from '@core/components/user/UserCarList'
import UserRoadList from '@core/components/user/UserRoadList'
import UserAlertsList from '@core/components/user/UserAlertsList'
import UserDevicesList from '@core/components/user/UserDevicesList'
import UserPayment from '@core/components/user/UserPayment'
import UserReductCodeList from '@core/components/user/UserReductCodeList'
import UserLocksList from '@core/components/user/UserLocksList'
import { useRouter } from '@core/utils'
import CustomToolbar from '@core/components/toolbar/CustomToolbar'
import store from '@/store'

export default {
  name: 'UserDetails',
  components: {
    CustomToolbar,
    UserLocksList,
    UserReductCodeList,
    UserPayment,
    UserDevicesList,
    UserAlertsList,
    UserRoadList,
    UserCarList,
    LicenseCard,
    LoaderDialog,
    UserBioPanel,
  },
  computed: {
    ...mapState('user', ['user']),
  },

  setup() {
    const { router } = useRouter()
    const isDialogVisible = ref(false)
    const userTab = ref(null)
    const componentKey = ref(0)
    // eslint-disable-next-line no-shadow,no-use-before-define
    const route = getCurrentInstance().proxy.$route

    const refreshComponent = () => {
      componentKey.value++
    }

    const getUser = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        if (store.state.user.user === null || store.state.user.user.id !== id) {
          isDialogVisible.value = true
          store.dispatch('user/getUser', id).then(() => {
            isDialogVisible.value = false
          }).catch(error => {
            console.log(error)
          })
        }
      }
    }

    const prev = () => {
      router.back()
    }

    const refreshObjet = () => {
      const id = route.params.id !== undefined ? route.params.id : null
      if (id !== null) {
        isDialogVisible.value = true
        store.dispatch('user/getUser', store.state.user.user.id).then(() => {
          isDialogVisible.value = false
        }).catch(error => {
          console.log(error)
        })
      }
    }

    onMounted(() => {
      getUser()
    })

    watch(() => store.state.user.refreshUserCount, () => {
      isDialogVisible.value = true
      store.dispatch('user/getUser', store.state.user.refreshUserId).then(() => {
        isDialogVisible.value = false
      }).catch(error => {
        console.log(error)
      })
      refreshComponent()
    }, { deep: true })

    const tabs = [
      { icon: mdiFileDocumentOutline, title: 'Permis', show: true },
      { icon: mdiCar, title: 'Voitures', show: true },
      { icon: mdiRoad, title: 'Trajets', show: store.state.user.currentUser.menu.road },
      { icon: mdiCashMultiple, title: 'Paiements', show: store.state.user.currentUser.menu.payment },
      { icon: mdiAlertCircle, title: 'Alertes', show: true },
      { icon: mdiDevices, title: 'Appareils', show: true },
      { icon: mdiCodeBraces, title: 'Codes de réduction', show: store.state.user.currentUser.menu.reduct_code },
      { icon: mdiLockOpenAlertOutline, title: 'Blocages', show: true },
    ]

    watch(() => store.state.user.lang, lang => {
      refreshObjet()
    })

    return {
      isDialogVisible,
      tabs,
      userTab,
      prev,
      refreshObjet,
      componentKey,
      icons: {
        mdiRefresh,
        mdiArrowLeftBoldCircleOutline,
      },
    }
  },
}
</script>

<style scoped>

</style>
