<template>
  <v-row class="user-bio-panel">
    <loader-dialog :is-dialog-visible="loaderDialog" />
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-alert
          v-model="isAlertVisible"
          :color="error ? 'error' : 'success'"
          dismissible
          text
          transition="slide-y-transition"
        >
          {{ reponseMsg }}
        </v-alert>
        <v-card-title class="justify-center flex-column">
          <v-badge
            :icon="userData.is_verified ? icons.mdiCheckDecagram : icons.mdiHelp"
            bottom
            overlap
            offset-x="15"
            offset-y="28"
          >
            <v-avatar
              :color="userData.profile_picture_path ? '' : 'primary'"
              :class="userData.profile_picture_path ? '' : 'v-avatar-light-bg primary--text'"
              size="120"
              rounded
              class="mb-4"
            >
              <v-img
                v-if="userData.profile_picture_path"
                :src="userData.profile_picture_path"
                contain
              ></v-img>
              <span
                v-else-if="userData.name"
                class="font-weight-semibold text-5xl"
              >{{ avatarText(userData.name) }}</span>
              <v-img
                v-else
                :src="require('@/assets/images/avatars/1.png')"
              />
            </v-avatar>
          </v-badge>

          <span class="mb-2">{{ userData.name }}</span>

          <v-chip
            label
            small
            :color="resolveUserRoleVariant(userData.role.code)"
            :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveUserRoleVariant(userData.role.code)}--text text-capitalize`"
          >
            {{ userData.role.label }}
          </v-chip>
          <v-chip
            label
            class="mt-2"
            :color="userData.is_locked ? 'error' : 'primary'"
          >
            <v-icon
              class="me-1"
              size="18"
            >
              {{ icons.mdiWalletTravel }}
            </v-icon>
            <strong> {{ formatAmount(userData.dynamic_balance) }} </strong>
          </v-chip>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Détails
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiAccount }}</v-icon>
                Nom:
              </span>
              <span class="text--secondary font-weight-bold">{{ userData.name }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">
                <v-icon size="15">{{ icons.mdiEmail }}</v-icon>
                Email:
              </span>
              <span class="text--secondary font-weight-bold">{{ userData.email }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiAccountQuestion }}</v-icon>
                Role:
              </span>
              <span class="text--secondary text-capitalize font-weight-bold">{{ userData.role.label }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiTextAccount }}</v-icon>
                Biographie:
              </span>
              <span class="text--secondary text-capitalize font-weight-bold">{{ userData.bio }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiCar }}</v-icon>
                Note chauffeur:
              </span>
              <span class="text--secondary text-capitalize font-weight-bold">
                <v-chip
                  small
                  color="info"
                >{{ userData.driver_note }} / 5</v-chip>
              </span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiSmokingOff }}</v-icon>
                Non fumeur:
              </span>
              <span class="text--secondary text-capitalize font-weight-bold">
                <v-chip
                  small
                  :color="userData.no_smoking ? 'primary' : 'error'"
                  :class="`${ userData.no_smoking ? 'primary' : 'error'}--text`"
                  class="v-chip-light-bg font-weight-bold"
                >
                  {{ userData.no_smoking === true ? 'OUI' : 'NON' }}
                </v-chip>
              </span>
            </v-list-item>
            <v-list-item
              v-show="userData.role.code === 'ADMIN'"
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiBellOutline }}</v-icon>
                Notification pour soumission de permis:
              </span>
              <span class="text--secondary text-capitalize font-weight-bold">
                <v-chip
                  small
                  :color="userData.notify_for_license ? 'primary' : 'error'"
                  :class="`${ userData.notify_for_license ? 'primary' : 'error'}--text`"
                  class="v-chip-light-bg font-weight-bold"
                >
                  {{ userData.notify_for_license === true ? 'OUI' : 'NON' }}
                </v-chip>
              </span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiThumbUp }}</v-icon>
                Préférences:
              </span>
              <span class="text--secondary text-capitalize font-weight-bold">{{ userData.preference }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiPhone }}</v-icon>
                Tel:
              </span>
              <span class="text--secondary font-weight-bold"> {{ (userData.country_code !== null ? `(${userData.country_code})` : '') }} {{ userData.phone_number }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiTranslate }}</v-icon>
                Langue:
              </span>
              <span class="text--secondary font-weight-bold">{{ userData.lang }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiCakeVariant }}</v-icon>
                Date de naissance:
              </span>
              <span class="text--secondary font-weight-bold">{{ userData.birthday !== null ? formattingDate(userData.birthday) : null }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiLogin }}</v-icon>
                Dernière connexion:
              </span>
              <span class="text--secondary font-weight-bold">{{ userData.last_logged_at !== null ? formattingDate(userData.last_logged_at) : null }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiEmailCheck }}</v-icon>
                Email validé le:
              </span>
              <span class="text--secondary font-weight-bold">{{ userData.email_verified_at !== null ? formattingDate(userData.email_verified_at) : null }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiPhoneCheck }}</v-icon>
                Téléphone validé le:
              </span>
              <span class="text--secondary font-weight-bold">{{ userData.phone_verified_at !== null ? formattingDate(userData.phone_verified_at) : null }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiFileCheck }}</v-icon>
                Permis de conduire validé le:
              </span>
              <span class="text--secondary font-weight-bold">{{ userData.license_validated_at !== null ? formattingDate(userData.license_validated_at) : null }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiFileCheck }}</v-icon>
                Permis de conduire rejeté le:
              </span>
              <span class="text--secondary font-weight-bold">{{ userData.license_rejected_at !== null ? formattingDate(userData.license_rejected_at) : null }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">
                <v-icon size="15">{{ icons.mdiFileCheck }}</v-icon>
                Motif du rejet:
              </span>
              <span class="text--secondary font-weight-bold">{{ userData.reject_reason }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-tooltip
            v-show="userData.role.code === 'MEMBER'"
            :color="userData.is_locked ? 'primary' : 'error'"
            left
          >
            <template #activator="{ on, attrs }">
              <v-btn
                x-large
                v-show="userData.role.code === 'MEMBER'"
                class="mb-4 me-3 mt-4"
                v-bind="attrs"
                :color="userData.is_locked ? 'primary' : 'error'"
                outlined
                icon
                elevation="5"
                v-on="on"
                @click="openDialog"
              >
                <v-icon
                  dark
                >
                  {{ userData.is_locked ? icons.mdiLockOpen : icons.mdiAccountLock }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ userData.is_locked ? "Débloquer" : "Bloquer" }}</span>
          </v-tooltip>
          <v-tooltip
            v-show="userData.role.code === 'ADMIN'"
            color="info"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="mb-4 me-3 mt-4"
                x-large
                v-bind="attrs"
                color="info"
                outlined
                elevation="5"
                icon
                v-on="on"
                @click="openForm"
              >
                <v-icon
                  dark
                >
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>
          <v-tooltip
            v-show="userData.role.code !== 'MEMBER'"
            :color="userData.notify_for_license ? 'error' : 'primary'"
            right
          >
            <template #activator="{ on, attrs }">
              <v-btn
                x-large
                v-show="userData.role.code !== 'MEMBER'"
                class="mb-4 me-3 mt-4"
                v-bind="attrs"
                :color="userData.notify_for_license ? 'error' : 'primary'"
                outlined
                elevation="5"
                icon
                v-on="on"
                @click="openDialogToSetNotif"
              >
                <v-icon
                  dark
                >
                  {{ userData.notify_for_license ? icons.mdiBellOutline : icons.mdiBellOffOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ userData.notify_for_license ? "Désactiver notifications" : "Activer notifications" }}</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
      <confirm-dialog
        :message="message"
        :dialog-delete="dialogConfirm"
        @clickout="dialogConfirm = false"
        @submitted="submitConfirmation"
      />
      <user-form-dialog
        :dialog="dialog"
        :user="userData"
        @clickout="closeDialog"
        @submitted="loading"
      />
      <user-lock-form-dialog
        :dialog="dialogLock"
        :user="userData"
        @clickout="closeDialogLock"
        @submitted="submitting"
        @error="errorForm"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiCheck,
  mdiBriefcaseVariantOutline,
  mdiCheckboxBlankCircle,
  mdiAccountLock,
  mdiLockOpen, mdiPencilOutline,
  mdiAccount, mdiPhone, mdiEmail,
  mdiCalendar, mdiCalendarMinus, mdiCalendarEdit, mdiCakeVariant,
  mdiAccountQuestion, mdiTranslate, mdiLogin, mdiEmailCheck,
  mdiPhoneCheck, mdiFileCheck, mdiTextAccount, mdiThumbUp, mdiCheckDecagram, mdiHelp, mdiSmokingOff,
  mdiWalletTravel, mdiLock, mdiBellOutline, mdiBellOffOutline, mdiCar
} from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import LoaderDialog from '@core/components/dialogs/LoaderDialog'
import ConfirmDialog from '@core/components/dialogs/ConfirmDialog'
import UserFormDialog from '@core/components/user/UserFormDialog'
import UserLockFormDialog from '@core/components/user/UserLockFormDialog'
import { formatDate } from '@core/date/dateFunctions'
import { ref } from '@vue/composition-api'
import { intToCurrency, notify } from '@core/dynamicComponents/dynamicComponents'
import store from '@/store'

export default {
  name: 'UserBioPanel',
  components: {
    UserLockFormDialog, UserFormDialog, ConfirmDialog, LoaderDialog,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const isAlertVisible = ref(false)
    const dialogConfirm = ref(false)
    const dialogLock = ref(false)
    const loaderDialog = ref(false)
    const isDialogVisible = ref(false)
    const dialog = ref(false)
    const error = ref(false)
    const to_lock_user = ref(false)
    const message = ref(null)
    const reponseMsg = ref(null)

    const formatAmount = amount => intToCurrency(store.state.user.lang, {
      style: 'currency',
      currency: 'XAF',
      minimumFractionDigits: 0,
    }, amount)

    const resolveUserRoleVariant = role => {
      if (role === 'MEMBER') return 'primary'
      if (role === 'ADMIN') return 'success'

      return 'primary'
    }

    const formattingDate = date => formatDate(date)

    const loading = () => {
      isDialogVisible.value = !isDialogVisible.value
    }

    const submitting = () => {
      loaderDialog.value = true
    }

    const errorForm = () => {
      loaderDialog.value = false
    }

    const closeDialog = () => {
      dialog.value = false
    }

    const closeDialogLock = () => {
      reponseMsg.value = store.state.user.lock_message
      dialogLock.value = false
      isAlertVisible.value = !!reponseMsg.value
      loaderDialog.value = false
      to_lock_user.value = false
    }

    const openForm = () => {
      store.state.user.user.birthday = new Date(store.state.user.user.birthday).toISOString().substr(0, 10)
      dialog.value = true
    }

    const openDialog = () => {
      if (store.state.user.user.role.code === 'MEMBER') {
        message.value = store.state.user.user.is_locked ? "Voulez-vous débloquer l'utilisateur?" : "Voulez-vous bloquer l'utilisateur?"
        to_lock_user.value = true
        if (store.state.user.user.is_locked === false) dialogLock.value = true
        else dialogConfirm.value = true
      } else dialogConfirm.value = true
    }

    const openDialogToSetNotif = () => {
      message.value = store.state.user.user.notify_for_license ? 'Voulez-vous désactiver les notifications.'
        : 'Voulez-vous activer les notifications'
      dialogConfirm.value = true
      to_lock_user.value = false
    }

    // eslint-disable-next-line camelcase
    const submitConfirmation = () => {
      loaderDialog.value = true
      if (to_lock_user.value === true) {
        store.dispatch('user/lockUser', { user: store.state.user.user, field: null }).then(response => {
          reponseMsg.value = response.message
          loaderDialog.value = false
          dialogConfirm.value = false
          error.value = false
          isAlertVisible.value = true
          notify(true, true, response.message)
        }).catch(err => {
          reponseMsg.value = err.response.data.message
          loaderDialog.value = false
          dialogConfirm.value = false
          error.value = true
          isAlertVisible.value = true
          notify(false, true, err.response.data.message)
        })
      } else {
        store.dispatch('user/activateAdminForSubmitLicense', store.state.user.user).then(response => {
          reponseMsg.value = response.message
          loaderDialog.value = false
          dialogConfirm.value = false
          error.value = false
          isAlertVisible.value = true
          notify(true, true, response.message)
        }).catch(err => {
          reponseMsg.value = err.response.data.message
          loaderDialog.value = false
          dialogConfirm.value = false
          error.value = true
          isAlertVisible.value = true
          notify(false, true, err.response.data.message)
        })
      }
    }

    return {
      formatAmount,
      openDialogToSetNotif,
      isAlertVisible,
      dialogConfirm,
      dialogLock,
      loaderDialog,
      isDialogVisible,
      dialog,
      error,
      message,
      reponseMsg,
      formattingDate,
      loading,
      submitting,
      errorForm,
      closeDialog,
      closeDialogLock,
      openForm,
      openDialog,
      submitConfirmation,
      avatarText,
      kFormatter,
      resolveUserRoleVariant,

      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiAccountLock,
        mdiLockOpen,
        mdiPencilOutline,
        mdiAccount,
        mdiPhone,
        mdiEmail,
        mdiCalendar,
        mdiCalendarMinus,
        mdiCalendarEdit,
        mdiCakeVariant,
        mdiAccountQuestion,
        mdiTranslate,
        mdiLogin,
        mdiEmailCheck,
        mdiPhoneCheck,
        mdiFileCheck,
        mdiTextAccount,
        mdiThumbUp,
        mdiCheckDecagram,
        mdiHelp,
        mdiSmokingOff,
        mdiWalletTravel,
        mdiLock,
        mdiBellOutline,
        mdiBellOffOutline,
        mdiCar,
      },
    }
  },
}
</script>

<style scoped>

</style>
